/* SearchBar.css */

.search-bar-wrapper {
  position: relative;
  width: 100%;
  max-width: 400px; /* Adjust based on your design */
  margin: 0 auto;
}

.search-bar {
  display: flex;
  align-items: center;
  position: relative;
}

.search-icon {
  position: absolute;
  left: 12px;
  color: #888;
  font-size: 1rem;
}

.search-bar input {
  width: 100%;
  padding: 10px 10px 10px 40px; /* Padding to accommodate the search icon */
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
  font-size: 1rem;
  background-color: #f9f9f9;
}

.search-bar input:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  background-color: #fff;
}

.autocomplete-suggestions {
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.suggestion-section {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.suggestion-section:last-child {
  border-bottom: none;
}

.suggestion-section h4 {
  margin: 0 15px 10px;
  font-size: 1rem;
  color: #555;
}

.autocomplete-suggestion {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.autocomplete-suggestion:hover {
  background-color: #f0f0f0;
}

.product-suggestion .suggestion-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
  flex-shrink: 0;
}

.category-suggestion .suggestion-name {
  font-weight: bold;
}

.suggestion-name {
  font-size: 0.95rem;
  color: #333;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments if needed */
@media (max-width: 768px) {
  .search-bar-wrapper {
    max-width: 100%;
  }

  .search-bar input {
    font-size: 0.9rem;
  }
}
