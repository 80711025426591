/* General styles */
body {
  font-family: "Arial, sans-serif";
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.header {
  background-color: #333;
  color: white;
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-links {
  display: flex;
  list-style-type: none;
}

.nav-links li {
  margin: 0 1rem;
}

.search-bar {
  padding: 0.5rem;
  border-radius: 4px;
  border: none;
}

.hero-banner {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.hero-content {
  max-width: 600px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  padding: 2rem;
}

.product-card {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar ul {
    flex-direction: column;
  }

  .category-card {
    flex: 1 1 100%;
  }

  .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

@media (min-width: 769px) {
  .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
