.news-slider {
  position: relative;
  width: 100%;
  height: 60vh;
  overflow: hidden;
  color: white;
  text-align: center;
}

.slide {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-image 1s ease-in-out;
}

.slide-content {
  max-width: 80%;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.slide-content h2 {
  font-size: 2rem;
  margin: 0 0 1rem;
}

.slide-content p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.read-more {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: white;
  background: #ff5722;
  border-radius: 4px;
  text-decoration: none;
  transition: background 0.3s;
}

.read-more:hover {
  background: #e64a19;
}

/* Slider Controls */
.slider-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.prev,
.next {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 1.5rem;
  border-radius: 50%;
  transition: background 0.3s;
}

.prev:hover,
.next:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* Slider Dots */
.slider-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.5rem;
}

.dot {
  width: 12px;
  height: 12px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;
}

.dot.active {
  background: #ff5722;
}

.dot:hover {
  background: rgba(255, 255, 255, 0.8);
}

@media (max-width: 768px) {
  .news-slider {
    height: 50vh;
  }

  .slide-content h2 {
    font-size: 1.5rem;
  }

  .slide-content p {
    font-size: 1rem;
  }

  .read-more {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
  }
}
