.hero-slider {
  position: relative;
  width: 100%;
  height: 60vh;
  overflow: hidden;
  color: white;
  text-align: center;
}

.slide {
  width: 100%;
  height: 100%;
  background-size: contain; /* Ensure the entire image is visible */
  background-position: center;
  background-repeat: no-repeat; /* Prevent image repetition */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-image 1s ease-in-out;
}

.slide-content {
  max-width: 80%;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.slide-content h1 {
  font-size: 2.5rem;
  margin: 0 0 1rem;
}

.slide-content p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

/* Updated "Shop Now" Button */
.shop-now {
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  background: linear-gradient(90deg, #ff5722 0%, #e64a19 100%);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.3s, box-shadow 0.3s;
}

.shop-now:hover {
  background: linear-gradient(90deg, #e64a19 0%, #ff5722 100%);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Slider Dots */
.slider-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.5rem;
}

.dot {
  width: 12px;
  height: 12px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;
}

.dot.active {
  background: #ff5722;
}

.dot:hover {
  background: rgba(255, 255, 255, 0.8);
}

@media (max-width: 768px) {
  .hero-slider {
    height: 50vh;
  }

  .slide-content h1 {
    font-size: 2rem;
  }

  .slide-content p {
    font-size: 1rem;
  }

  .shop-now {
    font-size: 0.875rem;
    padding: 0.75rem 1.5rem;
  }
}
