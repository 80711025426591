.homepage {
  padding: 2rem;
}

.category-section {
  margin-bottom: 3rem;
}

.category-section h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
  border-bottom: 2px solid #ff5722;
  padding-bottom: 0.5rem;
}

.view-more {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  background: #ff5722;
  border-radius: 4px;
  text-decoration: none;
  transition: background 0.3s;
}

.view-more:hover {
  background: #e64a19;
}

@media (max-width: 768px) {
  .category-section h2 {
    font-size: 1.5rem;
  }
}
.affiliate-disclaimer {
  background-color: #f7f7f7;
  padding: 1rem;
  text-align: center;
  margin-bottom: 1.5rem;
  border-radius: 8px;
  font-size: 0.9rem;
  color: #555;
}

.featured-articles {
  margin-bottom: 3rem;
}

.featured-articles h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
  border-bottom: 2px solid #ff5722;
  padding-bottom: 0.5rem;
}
