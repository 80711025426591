.header {
  background-color: #333;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.menu-icon {
  display: none;
  font-size: 28px;
  color: white;
  cursor: pointer;
}

/* Mobile Menu (Vertical) */
.nav-links-mobile {
  display: none;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #333;
  padding: 20px;
}

.nav-links-mobile.active {
  display: flex;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .menu-icon {
    display: block;
  }

  .nav-links-mobile {
    display: none;
  }

  .nav-links-mobile.active {
    display: flex;
  }
}

/* Ensure horizontal menu on larger screens */
@media (min-width: 769px) {
  .nav-links {
    display: flex !important;
  }

  .menu-icon {
    display: none;
  }

  .nav-links-mobile {
    display: none !important;
  }
}

.language-switcher {
  display: flex;
  gap: 10px;
  align-items: center;
}

.language-switcher button {
  background-color: #fff;
  border: none;
  color: #333;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.language-switcher button:hover {
  background-color: #ddd;
}

/* Adjust mobile styles if necessary */
@media (max-width: 768px) {
  .language-switcher {
    margin-left: 20px;
  }
}
