.comparison-wrapper {
  margin: 20px 0;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.highlight-box {
  background-color: #007bff;
  color: white;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.highlight-text {
  margin: 0;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
}

.comparison-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  text-align: left;
}

.comparison-header,
.comparison-row {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ddd;
}

.feature-header,
.product-header,
.feature,
.value {
  box-sizing: border-box;
  padding: 15px;
}

.feature-header,
.feature {
  background-color: #f1f1f1;
  font-weight: bold;
}

.product-header,
.value {
  text-align: center;
}

@media (min-width: 768px) {
  .feature-header,
  .feature {
    width: 20%;
  }

  .product-header,
  .value {
    width: 16%;
  }
}

@media (max-width: 767px) {
  .feature-header,
  .feature {
    width: 100%;
  }

  .product-header,
  .value {
    width: 100%;
  }

  .comparison-header,
  .comparison-row {
    display: block;
  }

  .comparison-row {
    margin-bottom: 20px;
  }
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #007bff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.comparison-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.comparison-card {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.comparison-card h3 {
  margin-top: 0;
}

.comparison-card ul {
  list-style: none;
  padding: 0;
}

.comparison-card li {
  margin-bottom: 10px;
}
