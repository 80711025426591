.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 20px;
  z-index: 1000;
}

.cookie-consent-banner p {
  margin: 0;
  font-size: 14px;
}

.cookie-consent-banner a {
  color: #fff;
  text-decoration: underline;
}

.cookie-consent-banner button {
  background-color: #ff5722;
  color: white;
  border: none;
  padding: 8px 16px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s;
}

.cookie-consent-banner button:hover {
  background-color: #e64a19;
}
