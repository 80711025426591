.news-article-page {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.news-article-page h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.news-article-date {
  font-size: 0.875rem;
  color: #888;
  margin-bottom: 1rem;
}

.news-article-image {
  width: 100%;
  height: auto;
  margin-bottom: 1.5rem;
  object-fit: cover;
  border-radius: 8px;
}

.news-article-content {
  font-size: 1.125rem;
  color: #555;
  line-height: 1.6;
}

.news-article-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.news-article-page h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}

.news-article-date {
  font-size: 0.9rem;
  color: #888;
  text-align: center;
  margin-bottom: 2rem;
}

.news-article-content {
  line-height: 1.6;
  font-size: 1.1rem;
  color: #444;
}

.news-article-content h2 {
  font-size: 2rem;
  color: #222;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.news-article-content h3 {
  font-size: 1.5rem;
  color: #333;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.news-article-content p {
  margin-bottom: 1.5rem;
}

.news-article-content img {
  max-width: 100%;
  height: auto;
  margin: 1.5rem 0;
}

.news-article-content a {
  color: #1e90ff;
  text-decoration: none;
  border-bottom: 1px solid #1e90ff;
  transition: color 0.2s;
}

.news-article-content a:hover {
  color: #555;
}

.news-article-content blockquote {
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #f5f5f5;
  border-left: 5px solid #1e90ff;
  font-style: italic;
}

.news-article-content ul,
.news-article-content ol {
  margin: 1.5rem 0;
  padding-left: 2rem;
}

.news-article-content li {
  margin-bottom: 0.5rem;
}

.news-article-content table {
  width: 100%;
  border-collapse: collapse;
  margin: 1.5rem 0;
}

.news-article-content table th,
.news-article-content table td {
  padding: 0.75rem;
  border: 1px solid #ddd;
  text-align: left;
}

.news-article-content table th {
  background-color: #f0f0f0;
}
.related-articles-section {
  margin-top: 2rem;
}

.related-articles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
}

.related-article-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.related-article-card:hover {
  transform: scale(1.05);
}

.related-article-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.related-article-card h3 {
  padding: 0.5rem;
  font-size: 1.25rem;
  text-align: center;
}
