.horizontal-slider {
  overflow-x: hidden;
  white-space: nowrap;
  padding: 10px 0;
  position: relative;
  width: 100%;
}

.slider-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slider-item {
  display: inline-block;
  width: 200px;
  margin-right: 15px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.slider-image {
  width: 100%;
  height: 150px; /* Set a consistent height for the images */
  object-fit: contain; /* Ensure images maintain their aspect ratio */
  border-radius: 5px;
}

.product-name {
  font-size: 14px;
  margin: 10px 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
}

.product-price {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

.buy-now-button {
  display: inline-block;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 14px;
}
