.news-page {
  padding: 2rem;
}

.news-page h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  text-align: left;
  color: #333;
}

.news-categories {
  margin-bottom: 2rem;
  display: flex;
  gap: 1rem;
}

.category-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #333;
  background: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.category-button.active {
  background: #ff5722;
  color: white;
  border-color: #ff5722;
}

.category-button:hover {
  background: #ff5722;
  color: white;
}

.news-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.news-item {
  display: flex;
  background: #f7f7f7;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.news-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.news-item-image {
  width: 40%;
  object-fit: cover;
}

.news-item-content {
  padding: 1.5rem;
  flex: 1;
}

.news-item-content h3 {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.news-item-date {
  font-size: 0.875rem;
  color: #888;
  margin-bottom: 1rem;
}

.news-item-content p {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #555;
}

.read-more {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  background: #ff5722;
  border-radius: 4px;
  text-decoration: none;
  transition: background 0.3s;
}

.read-more:hover {
  background: #e64a19;
}

@media (max-width: 1024px) {
  .news-item {
    flex-direction: column;
  }

  .news-item-image {
    width: 100%;
    height: 250px;
  }
}

@media (max-width: 768px) {
  .news-page h2 {
    font-size: 2rem;
  }

  .news-item-content h3 {
    font-size: 1.5rem;
  }

  .news-item-content p {
    font-size: 0.875rem;
  }

  .read-more {
    font-size: 0.875rem;
    padding: 0.5rem 0.75rem;
  }
}
