.search-category-results {
  margin-bottom: 20px;
}

.category-grid {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.category-card {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  width: 150px;
}

.category-card:hover {
  background-color: #f0f0f0;
}
