.product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.product-card {
  background: #f7f7f7;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 100%;
  max-width: 250px; /* Adjust the max-width as needed */
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.product-image {
  width: 100%;
  height: auto;
  max-height: 200px; /* Ensure the image doesn't get too tall */
  object-fit: contain; /* Maintain aspect ratio and contain within the box */
  margin-bottom: 1rem;
  border-radius: 4px;
}

.product-name {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.product-team {
  font-size: 1rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.product-price {
  font-size: 1.1rem;
  color: #777;
  margin-bottom: 1rem;
}

.buy-now-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background: #ff5722;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 1rem;
  transition: background 0.3s;
}

.buy-now-button:hover {
  background: #e64a19;
}
.category-link-button {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  font-weight: bold;
  transition: transform 0.3s ease, color 0.3s ease;
}

.category-link-button:hover {
  transform: scale(1.1);
  color: #0056b3;
}

.category-link-button:focus {
  outline: none;
}
