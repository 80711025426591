/* You can override Mailchimp's default styles or add your own styles here */

#mc_embed_signup {
  background: #fff;
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

#mc_embed_signup h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

#mc_embed_signup .button {
  background-color: #ff5722;
  color: white;
  border-radius: 4px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#mc_embed_signup .button:hover {
  background-color: #e64a19;
}

#mc_embed_signup input[type="email"],
#mc_embed_signup input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

#mc_embed_signup .indicates-required {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 10px;
}

#mc_embed_signup .asterisk {
  color: #ff5722;
}
